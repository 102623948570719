@use "src/styles/common";

.brand {
  cursor: pointer;

  &.primary path {
    fill: common.fn-color(primary);
  }

  &.white path {
    fill: common.fn-color(white);
  }

  &.black path {
    fill: common.fn-color(black);
  }
}
