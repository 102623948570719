@use 'src/styles/common';

.home {
  padding: 128px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 100px;

  .collectors,
  .creators {
    margin: 0 4%;
  }

  .title {
    margin-left: 4%;
    @include common.mn-font(white, 30px, 900, 38px);
    margin-bottom: 24px;
  }
}
