@use 'src/styles/common';

.tokenPreview {
  height: 100%;
  width: 100%;
  background: #1d1e20;
  border: 1px solid #292a2e;
  padding: 8px 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  .tokenInfo {
    padding: 16px;
    display: flex;
    gap: 4px;
    @include common.mn-font(white, 16pxx, 700, 24px);

    .collectionName {
    }

    .id {
      color: #cacbce;
      margin-left: 4px;
    }

    .badge {
      @keyframes flickerAnimation {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }

      margin-left: auto;
      animation: flickerAnimation 1s infinite;
    }
  }
}
