@use 'src/styles/common';

.appCollectionPreview {
  cursor: pointer;
  border: 0.2px solid rgba(59, 60, 63, 0.5);
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 16px;

  &.draft,
  &.noBackground {
    background: #1d1e20;
  }

  &.draft {
    .content .bottom {
      flex-direction: column;
      padding: 0 16px 16px;

      .finishButton {
        max-width: 281px;
        width: 100%;
        align-self: flex-end;
      }
    }
  }

  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.8;
    border-radius: 16px;
    overflow: hidden;
    top: 0;
    left: 0;

    img {
      position: absolute;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      padding: 16px;
      gap: 16px;

      .nftContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .nftImageContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 72px;
          width: 72px;
          border: 0.2px solid rgba(59, 60, 63, 0.5);
          border-radius: 8px;
          overflow: hidden;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      .name {
        min-height: 64px;
        @include common.mn-font(white, 24px, 900, 32px);
      }
    }

    .bottom {
      padding: 0 8px 8px;
      display: flex;
    }
  }

  &.hero {
    .content {
      .top {
        align-items: center;
        justify-content: center;

        .name {
          min-height: unset;
        }

        .nftContainer {
          flex-direction: column;
          align-items: center;
          gap: 20px;

          .nftImage {
            height: 160px;
            width: 160px;
          }
        }
      }

      .bottom {
        justify-content: center;
        padding: 0;
        transform: translateY(50%);
      }
    }
  }
}
