@use 'src/styles/common';

.collectionTypeCard {
  border: 1px solid #292a2e;
  border-radius: 28px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 470px;

  .title {
    @include common.mn-font(white, 24px, 700, 36px);
  }

  .descriptionContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .description {
      @include common.mn-font(white, 16px, 400, 24px);
      text-align: center;
      flex: 1;
      margin-bottom: 32px;
    }
  }

  .imageContainer {
    align-self: stretch;
    background: rgba(255, 255, 255, 0.07);
    border: 1px solid #292a2e;
    border-radius: 20px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    min-height: 222px;
  }
}
