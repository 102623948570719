@use '../../styles/common';

.optionsForm {
  .fieldDescription {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
  }

  .container {
    display: flex;
    gap: 36px;
  }

  .optionsForm form {
    min-height: unset;
    justify-content: flex-start;
    padding: 0;
    overflow: hidden;
  }
}

.loader {
  height: 100% !important;
}
