.userFanCollections {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .item {
    width: 313px;
    height: 470px;
    border-radius: 12px;
  }

  .noItems {
    margin: 0 auto;
  }
}
