@use '../../../../styles/common';

.collectionMinter {
  width: 336px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  background: common.fn-color(dark, 8);
  border-radius: 20px;
  overflow: hidden;

  .header {
    background: common.fn-color(dark, 6);
    gap: 12px;
    display: flex;
    padding: 16px;

    .image {
      height: 40px;
      width: 40px;
      border-radius: 4px;
    }

    .title {
      @include common.mn-font(white, 16px, 900, 24px, -0.01em);
    }

    .filmMaker {
      @include common.mn-font(white, 12px, 500, 18px);
    }
  }

  .content {
    padding: 16px 24px 20px;

    .stageBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .stage {
        @include common.mn-font(white, 20px, 900, 30px, -0.01em);
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 6px;
        height: 20px;
        border-radius: 6px;
        background: common.fn-color(primary, 9);
        @include common.mn-font(primary, 14px, 500, 20px, '', 5);
      }
    }

    .stats {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 14px;
      margin-bottom: 14px;

      .row {
        display: flex;
        justify-content: space-between;
        @include common.mn-font(#818181, 16px, 400, 24px, '', 0);

        .value {
          color: common.fn-color(white);
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 9px;
        }
      }
    }

    .iconEdit {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }

    .button {
      width: 100%;
    }
  }
}
