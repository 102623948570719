.userFundingCollections {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .item {
    height: 431px;
    width: 646px;
    border-radius: 12px;
  }

  .noItems {
    margin: 0 auto;
  }
}
