@use 'src/styles/common';

.pdfModal {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 80vw;
  padding-top: 1rem;
  position: relative;
  align-items: center;
  justify-content: center;

  .innerLoader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .pdfContainer {
    flex: 1;
    overflow-y: scroll;
  }

  .pages {
    z-index: 1;
    position: absolute;
    top: 30px;
    left: 10px;
    background: #292a2e;
    border-radius: 6px;
    padding: 2px 4px;
    @include common.mn-font(white, 12px, 500, 16px);
  }

  .control {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    width: 32px;
    height: 54px;
    justify-content: center;
    align-items: center;
    background: #292a2e;
    border-radius: 6px;
    color: white;

    &.left {
      left: 0;
      transform: translate(50%, -50%);
    }

    &.right {
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
}
