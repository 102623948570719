@use 'src/styles/common';

.appTabsVertical {
  width: 100%;
  padding-top: 42px;

  .header {
    border: none;
    padding-right: 85px;
  }

  .tab {
    border: none;
    background: none;
    @include common.mn-font(#818181, 14px, 600, 20px, -0.03em);
    padding: 0;
    margin-bottom: 14px;

    &[aria-selected='true'] {
      color: white;
      font-weight: 900;
    }
  }

  &.stepper {
    .tab {
      margin-bottom: 40px;

      &:not(:last-of-type) .tabLabel .step:after {
        display: block;
        height: 24px;
        width: 1px;
        border-left: 2px solid #2c2c2c;
        position: absolute;
        content: '';
        margin-top: 60px;
      }

      .tabLabel {
        display: flex;
        gap: 8px;

        .step {
          border-radius: 50%;
          border: 1px solid #2c2c2c;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: common.fn-color(black);
          @include common.mn-font(#818181, 12px, 600, 12px);
        }
      }

      &[aria-selected='true'] {
        color: common.fn-color(primary, 5);
        font-weight: 900;

        .step {
          background: common.fn-color(primary, 5);
          color: common.fn-color(black);
          border: none;
        }
      }

      &:disabled {
        opacity: 1;
      }

      &:not(.done):disabled {
        filter: brightness(50%);
      }

      &.done {
        color: white;
        cursor: not-allowed;

        .tabLabel .step {
          background: white;
          color: black;
        }
      }
    }
  }
}
