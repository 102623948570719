@use '../../styles/common';

.collectionEdit {
  display: flex;
  flex-direction: column;
  padding: 157px 64px;
  background: black;
  flex: 1;
  max-width: common.$var-container-width;

  .title {
    @include common.mn-font(white, 30px, 900, 38px, -0.03em);
  }

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #2c2c2c; // TODO:
    padding-bottom: 20px;

    .buttons {
      display: flex;
      gap: 16px;
    }
  }
}
