@use 'src/styles/common';

.connect {
  padding-top: 88px;
  width: 100%;
  display: flex;
  height: 100vh;

  img {
    max-width: 388px;
    height: auto;
  }

  .wallet {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 356px;
    padding: 10px 16px;
    border: 1px solid #303136;
    border-radius: 12px;
    cursor: pointer;

    .icon {
      height: 24px;
      width: 24px;
    }

    .name {
      @include common.mn-font(white, 16px, 500, 24px);
    }
  }

  .error {
    @include common.mn-font(red, 10px, 400, 15px);
  }

  .wallets {
    padding: 0 64px;
    max-width: 744px;

    .workingWallets,
    .upcomingWallets {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .titleContainer {
        display: flex;
        gap: 12px;
        align-items: center;
      }

      .title {
        @include common.mn-font(white, 36px, 700, 48px);
      }

      .description {
        @include common.mn-font(white, 18px, 400, 28px);
        margin-bottom: 20px;
      }
    }

    .workingWallets {
      padding: 40px 0 32px;
      border-bottom: 1px solid #292a2e;
    }

    .upcomingWallets {
      padding: 40px 0 32px;
    }
  }
}
