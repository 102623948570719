@use '../../styles/common';

.profileForm {
  .form form {
    padding: 0;

    .background {
      width: 100%;
      height: 236px;
    }

    .avatar {
      height: 200px;
      width: 200px;
      margin-top: -140px;
      margin-bottom: 24px;
      z-index: 1;
    }

    .fields {
      width: 100%;
      padding: 0 188px 32px;
    }
  }

  .withMargin {
    margin-bottom: 24px;
  }

  .row {
    display: flex;
    gap: 20px;

    > div {
      flex: 1;
    }
  }

  .subTitle {
    @include common.mn-font(white, 20px, 600, 30px); // :TODO
  }

  .fieldDescription {
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
    margin-bottom: 16px;
  }

  .socialInput {
    border-top: 1px solid #292a2e;
    display: flex;
    padding: 20px 0;
    align-items: center;

    .label {
      @include common.mn-font(#cacbce, 14px, 600, 20px);
      min-width: 150px;
    }

    .input {
      flex: 1;
    }
  }
}
