@use 'src/styles/common';

.topCreators {
  border: 1px solid #292a2e;
  border-radius: 28px;
  display: grid;
  grid-auto-flow: column;

  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;

  header {
    background: #1d1e20;
    @include common.mn-font(white, 12px, 600, 18px);
  }

  .creator {
    &:not(:nth-of-type(5n)) {
      border-bottom: 1px solid #292a2e;
    }

    .place {
      @include common.mn-font(white, 30px, 600, 38px);
    }

    .followers {
      @include common.mn-font(white, 20px, 600, 30px);
    }

    .button {
      border-radius: 24px;
      padding: 10px 35px;
    }
  }

  .column {
    display: flex;
    padding: 10px 30px;
    height: 65px;

    > * {
      align-items: center;
      display: flex;

      &:nth-of-type(1) {
        flex: 0.7;
      }

      &:nth-of-type(2) {
        flex: 2.5;
      }

      &:nth-of-type(3) {
        flex: 1;
      }

      &:nth-of-type(4) {
        flex: 2;
      }
    }
  }

  header:last-of-type {
    order: 2;
  }

  div:nth-of-type(n + 6) {
    order: 2;
  }

  @media (max-width: common.$var-breakpoint-xxl) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;

    header:last-of-type {
      display: none;
    }

    .creator:not(:last-of-type) {
      border-bottom: 1px solid #292a2e;
    }
  }
}
