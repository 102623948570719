@use '../../../../styles/common';

.collectionHero {
  padding: 180px 60px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  #myFogCanvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    max-width: 100%;
    max-height: 100%;
    height: 100% !important;
    width: 100% !important;
  }

  .gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 18%, rgba(255, 255, 255, 0) 100%);
  }

  .title {
    padding: 32px 0 24px;
    @include common.mn-font(white, 48px, 700, 60px, -0.03em);
    text-align: center;
  }

  .actions {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  @media (max-width: common.$var-breakpoint-md) {
    padding: 120px 40px 42px;

    .title {
      padding: 15px 0 20px;
      @include common.mn-font(white, 24px, 700, 32px);
    }

    .actions {
      margin-top: 24px;
    }
  }
}
