@use 'src/styles/common';

.profile {
  display: flex;
  flex-direction: column;
  padding-top: 88px;
  max-width: common.$var-container-width;
  width: 100%;

  .header {
    width: 100%;
    position: relative;

    .socials {
      position: absolute;
      right: 64px;
      bottom: 20px;
    }

    .background {
      height: 300px;
      width: 100%;
      border-radius: 0;
      object-fit: cover;
      border-bottom: 1px solid #303136;

      &.noImage {
        background: #1d1e20;
        object-fit: contain;
        object-position: right;
      }
    }

    .avatar {
      height: 200px;
      width: 200px;
      border-radius: 50%;
      position: absolute;
      left: 64px;
      bottom: 0;
      transform: translateY(40px);
      border: 1px solid #303136;
    }
  }

  .info {
    padding: 64px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .actionsSkeleton,
    .nameSkeleton,
    .usernameSkeleton {
      border-radius: 12px;
    }

    .nameSkeleton {
      width: 245px;
      min-height: 60px;
    }

    .usernameSkeleton {
      width: 300px;
      min-height: 30px;
    }

    .actionsSkeleton {
      width: 400px;
      min-height: 44px;
    }

    .name {
      @include common.mn-font(white, 48px, 700, 60px);
    }

    .usernameContainer {
      display: flex;
      gap: 24px;
      align-items: center;

      .username {
        @include common.mn-font(white, 20px, 600, 30px);
      }

      .address {
        display: flex;
        gap: 4px;
        align-items: center;
        @include common.mn-font(white, 16px, 500, 24px);
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 24px;

      .button {
        padding: 10px 32px;
      }
    }

    .bio {
      border: 1px solid #292a2e;
      border-radius: 28px;
      padding: 20px;
      max-width: 736px;
      @include common.mn-font(#cacbce, 16px, 400, 24px);
    }
  }

  .tabs {
    padding: 64px;
    min-height: 768px;
  }
}
