@use '../../../../styles/common';

.collectionMinter {
  min-width: 336px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  background: common.fn-color(dark, 8);
  border-radius: 20px;
  overflow: hidden;

  .header {
    align-items: flex-start;
    background: #292a2e;
    gap: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px 22px;

    .title {
      margin-top: 10px;
      @include common.mn-font(white, 16px, 900, 24px, -0.01em);
    }

    .filmMaker {
      @include common.mn-font(white, 12px, 500, 18px);
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .content {
    padding: 20px 22px;
    background: #1d1e20;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .valueBlock {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .collected {
        @include common.mn-font(lime, 30px, 600, 38px);
      }

      .goal {
        @include common.mn-font(#818181, 14px, 400, 20px);
      }
    }

    .options {
      background: #292a2e;
      border: 1px solid #292a2e;
      border-radius: 16px;
      padding: 16px 25px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .stat {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .statName {
          @include common.mn-font(#818181, 14px, 400, 20px);
        }

        .statValue {
          display: flex;
          gap: 6px;
          @include common.mn-font(white, 20px, 600, 30px);
        }
      }

      .divider {
        width: 0;
        height: 48px;

        border: 1px solid rgba(129, 129, 129, 0.2);
      }
    }
  }
}
