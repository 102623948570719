.userShelf {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 52px 0;

  .item {
    width: 313px;
    height: 470px;
    border-radius: 16px;
  }

  .noItems {
    margin: 0 auto;
  }
}
