@use 'src/styles/common';

.appNoItems {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  .info {
    text-align: center;

    .title {
      @include common.mn-font(white, 20px, 700, 30px);
    }

    .description {
      @include common.mn-font(white, 12px, 400, 18px);
    }
  }
}
