@use '../../../../styles/common';

.collectionHero {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  #myFogCanvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    max-width: 100%;
    max-height: 100%;
    height: 100% !important;
    width: 100% !important;
  }

  .gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 18%, rgba(255, 255, 255, 0) 100%);
  }

  .container {
    padding: 96px 42px 0;
    max-width: common.$var-container-width;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .name {
    }

    .galleryAndMinter {
      display: flex;
      gap: 40px;
      justify-content: space-between;

      .gallery {
        max-width: 888px;
      }
    }
  }

  @media (max-width: common.$var-breakpoint-md) {
    .container {
      padding: 128px 12px 0;

      .galleryAndMinter {
      }

      .name {
        @include common.mn-font(white, 24px, 700, 32px);
      }
    }
  }

  @media (min-width: common.$var-breakpoint-md) {
    .name {
      @include common.mn-font(white, 48px, 700, 60px);
    }
  }

  @media (max-width: common.$var-breakpoint-lg) {
    .container {
      .galleryAndMinter {
        flex-direction: column;
      }
    }

    .name {
      text-align: center;
    }
  }

  @media (min-width: common.$var-breakpoint-xxl) {
    .container {
      padding: 128px 84px 0;

      .name {
      }
    }
  }
}
