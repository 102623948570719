@use 'src/styles/common';

.container {
  display: flex;
  flex-direction: column;
  background: common.fn-color(black);
  min-height: 100vh;

  .header {
    position: absolute;
    top: 0;
    z-index: 10;
    //left: 50%;
    //transform: translateX(-50%);
    //max-width: common.$var-container-width;
  }

  .outlet {
    display: flex;
    flex: 1;
    justify-content: center;
  }
}
