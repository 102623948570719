@use 'src/styles/common';

.tokenImage {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  .image {
    height: 100%;
    width: 100%;
  }

  .symbol {
    @include common.mn-font(black, 12px, 500, 18px);
    padding: 6px 8px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
